import React from 'react';

var url = process.env.PUBLIC_URL

const Help = () => {
    const pdfUrl = url + '/manual_webgis.pdf'; // Ensure this path is correct

    return (
        <div style={{ height: '100vh' }}>
            <iframe
                src={pdfUrl}
                title="Help PDF"
                width="100%"
                height="100%"
                style={{ border: 'none' }}
            />
        </div>
    );
};

export default Help;



