import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/'

const SearchComponent = ({ onPolygonSelect, projectId }) => {
    const [resultados, setResultados] = useState([]);

    const buscar = async (termo) => {
        try {
            const response = await axios.get(
                `${API_URL}/api/main/search-attributes/`, {
                params: { termo, project_id: projectId },
                headers: {
                    Authorization: `Bearer ${Cookies.get('access_token')}`,
                },
            });
            setResultados(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (event) => {
        const termo = event.target.value;
        if (termo.length >= 3) {
            buscar(termo);
        } else {
            setResultados([]);
        }
    };

    const handleResultClick = (geojson) => {
        const geojsonData = JSON.parse(geojson);
        let bounds = [];
    
        const invertCoordinates = (coordinates) => coordinates.map(coord => [coord[1], coord[0]]);
    
        switch (geojsonData.type) {
            case "Polygon":
                bounds = invertCoordinates(geojsonData.coordinates[0]);
                break;
            case "MultiPolygon":
                geojsonData.coordinates.forEach(polygon => {
                    bounds = bounds.concat(invertCoordinates(polygon[0]));
                });
                break;
            case "Point":
                const pointCoordinates = geojsonData.coordinates;
                bounds = [[pointCoordinates[1], pointCoordinates[0]], [pointCoordinates[1], pointCoordinates[0]]];
                break;
            case "MultiPoint":
                bounds = invertCoordinates(geojsonData.coordinates);
                break;
            case "LineString":
                bounds = invertCoordinates(geojsonData.coordinates);
                break;
            case "MultiLineString":
                geojsonData.coordinates.forEach(line => {
                    bounds = bounds.concat(invertCoordinates(line));
                });
                break;
            default:
                console.error("Unsupported GeoJSON type:", geojsonData.type);
                return;
        }
    
        onPolygonSelect(bounds);
        setResultados([]);
    };
    

    return (
        <div style={{
            position: 'absolute',
            right: '80px',
            padding: '5px',
            borderRadius: '5px',
            zIndex: 1000,
        }}>
            <div className="input-field col s12">
                <input
                    type="text"
                    id="autocomplete-input"
                    className="autocomplete"
                    onChange={handleInputChange}
                />
                <label htmlFor="autocomplete-input">Buscar</label>
                {resultados.length > 0 && (
                    <ul className="autocomplete-content dropdown-content">
                        {resultados.map((resultado) => (
                            <li key={resultado.id} onClick={
                                () => handleResultClick(resultado.geojson)}>
                                <span>{resultado.name}</span>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default SearchComponent;
