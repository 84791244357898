import React, { useState } from 'react';
import { useEffect } from 'react';
import 'materialize-css';
import M from 'materialize-css';
import './Navbar.css'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/user';

var url = process.env.PUBLIC_URL

const languages = {
    "en":<img className='icon-nav-lan' src={url + "/united-states-of-america-flag-png-large.png"}/>,
    "pt-br":<img className='icon-nav-lan' src={url + "/brazil-flag-png-large.png"}/>,
}

const Navbar = () => {

    const dispatch = useDispatch();

    useEffect(()=>{
        var options = {}
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, options);

        var options = {
            coverTrigger: false,
            alignment:"bottom",
            constrainWidth:false
        }
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, options);
    },[])
	const { isAuthenticated } = useSelector(state => state.user);

    var guest_links =
    <>
        <li><a className='black-text' href="/login">Login</a></li>
        <li><a className='black-text' href="/register">Register</a></li>
    </>

    var auth_links = 
    <>  
        <li><a className='black-text' href="/project">Projetos</a></li>
        <li><a className='black-text' href="/dashboard">Perfil</a></li>
        <li><a className='black-text' href='#!' onClick={() => dispatch(logout())}>Sair</a></li>
    </>

    var link_itens = 
    <>
        {isAuthenticated ? auth_links : guest_links}
        <li><a className='black-text' href="/about">Sobre</a></li>
        <li><a className='black-text' href="/ajuda">Ajuda</a></li>
    </>

    

    return (
    <>
        <nav className='nav-wg'>
            <div className="nav-wrapper">
            <a href="/#" className="brand-logo">
                <img className="img-logo" src={url + "/websig.png"} alt="Web GIS Logo" height={60} />
            </a>
            <a href="#" data-target="mobile-demo" className="sidenav-trigger black-text">
                <i className="material-icons">menu</i>
            </a>
            <ul className="hide-on-med-and-down right">
                {link_itens}
            </ul>
            </div>
        </nav>

        <ul className="sidenav" id="mobile-demo">
            {link_itens}
        </ul>
    </>
);
};
export default Navbar;